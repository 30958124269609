<template>
  <div class="columns">
    <div class="column is-2">
      {{ formatDate(newsItem.date) }}
    </div>
    <div class="column" v-html="newsItem.content"></div>
  </div>
</template>

<script>
import { formatDate } from "@/utils";
export default {
  name: "NewsItem",
  props: ["newsItem"],
  methods: {
    formatDate,
  },
};
</script>
