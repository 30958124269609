import { defineStore } from "pinia";
import httpHelper from "@/utils/modules/http.helper";

// helpers

function parseDate(date) {
  let splitDate = date.split(".");
  splitDate[0] = parseInt(splitDate[0]); // day
  splitDate[1] = parseInt(splitDate[1]) - 1; // month
  splitDate[2] = parseInt(splitDate[2]); // year

  return Date.UTC(splitDate[2], splitDate[1], splitDate[0]);
}

export const useCountryStore = defineStore("country", {
  state: () => ({
    alldata: [],
    areas: [
      // Catch-all 'country' for all that are not defined separately below
      // when adding a new country take care to remove it from areas[0].countries!
      {
        name: "United States",
        code: "us",
        adjective: "US",
        countries: ["US"],
      },
      {
        name: "Euro Area",
        code: "ea_agg",
        adjective: "Euro Area",
        countries: [
          "CY",
          "EE",
          "EL",
          "FI",
          "GR",
          "LU",
          "LV",
          "LT",
          "MT",
          "SI",
          "SK",
        ],
      },
      {
        name: "Germany",
        code: "de",
        adjective: "German",
        countries: ["DE"],
      },
      {
        name: "United Kingdom",
        code: "gb",
        adjective: "British",
        countries: ["GB"],
      },
      {
        name: "France",
        code: "fr",
        adjective: "French",
        countries: ["FR"],
      },
      {
        name: "Italy",
        code: "it",
        adjective: "Italian",
        countries: ["IT"],
      },
      {
        name: "Spain",
        code: "es",
        adjective: "Spanish",
        countries: ["ES"],
      },
      {
        name: "Portugal",
        code: "pt",
        adjective: "Portugese",
        countries: ["PT"],
      },
      {
        name: "Netherlands",
        code: "nl",
        adjective: "Dutch",
        countries: ["NL"],
      },
      {
        name: "Switzerland",
        code: "ch",
        adjective: "Swiss",
        countries: ["CH"],
      },
      {
        name: "Poland",
        code: "pl",
        adjective: "Polish",
        countries: ["PL"],
      },
      {
        name: "Sweden",
        code: "se",
        adjective: "Swedish",
        countries: ["SE"],
      },
      {
        name: "Ireland",
        code: "ie",
        adjective: "Irish",
        countries: ["IE"],
      },
      {
        name: "Belgium",
        code: "be",
        adjective: "Belgian",
        countries: ["BE"],
      },
      {
        name: "Austria",
        code: "at",
        adjective: "Austrian",
        countries: ["AT"],
      },
      {
        name: "Romania",
        code: "ro",
        adjective: "Romanian",
        countries: ["RO"],
      },
      {
        name: "Bulgaria",
        code: "bg",
        adjective: "Bulgarian",
        countries: ["BG"],
      },
    ],
    activeCountry: {
      name: "Switzerland",
      code: "ch",
      adjective: "Swiss",
      countries: ["CH"],
    },
    indicator: { name: "GDP", indicator: "gdp" },
    colors: ["#eff3ff", "#bdd7e7", "#6baed6", "#3182bd", "#08519c"],
    quarters: [],
  }),
  getters: {
    activeIndicator: (state) => {
      if (state.activeCountry.code == "ie") {
        return { name: "MDD", indicator: "gdp" };
      }
      return state.indicator;
    },
    getIndicatorTPHistory: (state) => (countryCode, indicator, targetPeriod) =>
      state.alldata
        .filter(
          (o) =>
            o.country === countryCode.code &&
            o.target_variable === indicator &&
            o.target_period === targetPeriod,
        )
        .map((o) => {
          return [parseDate(o.kofcast_datestamp), Number(o.value)];
        }),
    getIndicatorValueFromCountry:
      (state) => (countryCode, indicator, targetPeriod) => {
        let data = state.alldata.filter(
          (o) =>
            o.country === countryCode &&
            o.target_variable === indicator &&
            o.target_period === targetPeriod,
        );
        if (data.length) {
          return Number(data[data.length - 1].value);
        }
        return null;
      },
    minMaxTargetPeriodDates:
      (state) => (countryCode, indicator, targetPeriod) => {
        const data = state.alldata.filter(
          (o) =>
            o.target_variable === indicator &&
            o.country === countryCode &&
            o.target_period === targetPeriod,
        );
        const min = Math.min(
          ...data.map((o) => parseDate(o.kofcast_datestamp)),
        ); //.reduce((prev, curr) => Math.min(prev.kofcast_datestamp, curr.kofcast_datestamp), parseDate("20.12.2099"))
        const max = Math.max(...data.map((o) => parseDate(o.nextrelease))); //.reduce((prev, curr) => Math.max(prev.nextrelease, curr.nextrelease), parseDate("30.12.1900"))
        return [min, max];
      },
  },
  actions: {
    async loadData(quarter) {
      const response = await httpHelper.get("/api/output", {
        params: { indicator: "en", model: "all", targetperiod: quarter },
      });
      this.alldata = response.data;
    },
    async loadQuarters() {
      const response = await httpHelper.get("/api/quarters");
      this.quarters = response.data;
    },
    setActiveCountry(name) {
      this.activeCountry = this.areas.find((area) => area.name === name);
    },
  },
});
