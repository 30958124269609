<template>
  <div>
    <h1 class="title is-2">
      The Nowcasting Lab: Cooperation and Supporting Partners
    </h1>

    <div class="partner-grid">
      <div class="columns is-multiline">
        <div class="column is-4" v-for="p in partners" :key="p.label">
          <PartnerCard :partner="p" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PartnerCard from "./PartnerCard.vue";

export default {
  components: { PartnerCard },
  data() {
    return {
      partners: [
        {
          label:
            "European Commission, Directorate-General for Economic and Financial Affairs (DG ECFIN)",
          alt_label: "European Commission",
          logo: "/partners/logo_ec.jpg",
          l_align: false,
        },
        {
          label: "State Secretariat for Economic Affairs SECO, Switzerland",
          alt_label: "State Secretariat for Economic Affairs SECO",
          logo: "/partners/seco.jpg",
          l_align: false,
        },
        {
          label: "Ministry of Finance, Portugal",
          alt_label: "GPEARI, Ministry of Finance, Portugal",
          logo: "/partners/logo_pt.png",
          l_align: false,
        },
        {
          label: "University of St. Gallen, Switzerland",
          alt_label: "University of St. Gallen",
          logo: "/partners/hsg.svg",
          l_align: false,
        },
        {
          label: "University of Manchester, United Kingdom",
          alt_label: "The University of Manchester",
          logo: "/partners/manu.jpg",
          l_align: false,
        },
        {
          label: "Inter-American Development Bank",
          label_class: "shift-up",
          alt_label: "Inter-American Development Bank",
          logo: "/partners/idb_logo.jpg",
          l_align: false,
        },
        {
          label: "Economic and Social Research Institute, Ireland",
          alt_label: "Economic and Social Research Institute",
          logo: "/partners/esri.png",
          l_align: false,
        },
      ],
    };
  },
};
</script>

<style scoped>
.partner-grid {
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100%;
}

.column {
  margin-top: auto;
  margin-bottom: auto;
}
</style>

<style>
.shift-up {
  position: relative;
  top: -45px;
}
</style>
