<template>
  <div class="partner-container">
    <div v-if="!partner.l_align">
      <div style="text-align: center">
        <img :src="partner.logo" :alt="partner.alt_label" />
      </div>
      <div class="partner-label" :class="partner.label_class">
        {{ partner.label }}
      </div>
    </div>
    <div v-else class="columns is-vcentered is-gapless">
      <div class="column">
        <div class="partner-label-left" :class="partner.label_class">
          {{ partner.label }}
        </div>
      </div>
      <div class="column is-8">
        <img :src="partner.logo" :alt="partner.alt_label" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["partner"],
};
</script>

<style scoped>
.partner-container {
  padding: 12px;
}

.partner-label {
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
}

.partner-label-left {
  font-weight: bold;
  text-align: right;
  white-space: pre-wrap;
  margin-right: 3px;
}
</style>
