import httpHelper from "@/utils/modules/http.helper";
import { parseDate, formatDate } from "@/utils";
import colorHelper from "@/utils/modules/color.helper";
import { defineStore } from "pinia";

export const useDetailStore = defineStore("detail", {
  state: () => ({
    model: "all",
    activeDay: 0,
    histogramData: [],
    growthHistoryData: [],
    measuresData: [],
    releaseData: [],
    outputData: [],
    impactData: [],
    contributionData: [],
  }),
  getters: {
    getDataModel(state) {
      return state.model;
    },
    getActiveDay(state) {
      return state.activeDay;
    },
    getMeasuresData(state) {
      return Object.fromEntries(
        state.measuresData
          .filter((obj) => obj.model === state.model)
          .map((obj) => {
            const parsedDate = parseDate(obj.kofcast_datestamp);
            return [
              parsedDate,
              {
                date: parsedDate,
                rmsfe: Number(obj.rmsfe),
                bias: Number("bias" in obj ? obj.bias : obj.skewness),
                lowerBound: Number(obj.lowerbound),
                upperBound: Number(obj.upperbound),
              },
            ];
          }),
      );
    },
    getMeasuresFromActiveDay(state) {
      return this.getMeasuresData[state.activeDay];
    },
    getCurrentRMSFE() {
      const measure = this.getMeasuresFromActiveDay;
      if (measure) {
        return measure.rmsfe;
      }
      return undefined;
    },
    getCurrentLowerBound() {
      const measure = this.getMeasuresFromActiveDay;
      if (measure) {
        return measure.lowerBound;
      }
      return undefined;
    },
    getCurrentUpperBound() {
      const measure = this.getMeasuresFromActiveDay;
      if (measure) {
        return measure.upperBound;
      }
      return undefined;
    },
    getCurrentBias() {
      const measure = this.getMeasuresFromActiveDay;
      return measure?.bias;
    },
    getStructuredHistogramData(state) {
      return state.histogramData.reduce((newData, obj) => {
        newData[obj.model] = newData[obj.model] || {};
        let kofcast_datestamp = parseDate(obj.kofcast_datestamp);
        newData[obj.model][kofcast_datestamp] =
          newData[obj.model][kofcast_datestamp] || [];
        newData[obj.model][kofcast_datestamp].push({
          x: Number(obj.bin_mean),
          y: Number(obj.nobs),
        });
        return newData;
      }, {});
    },
    getHistogramData(state) {
      return this.getStructuredHistogramData[state.model] || {};
    },
    getGrowthHistoryData(state) {
      let data = state.growthHistoryData
        .filter(
          (obj) =>
            obj.variable === "historic" && obj.date.split("-")[0] >= 2000,
        )
        .map((obj) => ({
          x: Date.parse(obj.date),
          y: Number(obj.value),
        }));
      return data;
    },
    getGrowthHistoryForecastData(state) {
      let data = state.growthHistoryData
        .filter(
          (obj) =>
            obj.variable === state.model && obj.date.split("-")[0] >= 2000,
        )
        .map((obj) => ({
          x: Date.parse(obj.date),
          y: Number(obj.value),
        }));
      return data;
    },
    getStructuredReleaseData(state) {
      let id = 1;
      return state.releaseData.reduce((newObj, obj) => {
        newObj[obj.model] = newObj[obj.model] || {};
        let kofcast_datestamp = parseDate(obj.kofcast_datestamp);
        newObj[obj.model][kofcast_datestamp] =
          newObj[obj.model][kofcast_datestamp] || [];

        newObj[obj.model][kofcast_datestamp].push({
          title: obj.title,
          id: id++,
          description: obj.description,
          impact: obj.impact,
          previousValue: obj.previous_value,
          actualValue: obj.actual_value,
          referencePeriod: formatDate(obj.reference_period),
          category: obj.category,
          source: obj.source,
          unit: obj.unit,
        });
        return newObj;
      }, {});
    },
    getReleaseData(state) {
      return this.getStructuredReleaseData[state.model] || {};
    },
    getOutputData(state) {
      return state.outputData
        .filter((obj) => obj.model === state.model)
        .map((obj) => ({
          x: parseDate(obj.kofcast_datestamp),
          y: Number(obj.value),
          selected: parseDate(obj.kofcast_datestamp) === state.activeDay,
        }));
    },
    getCurrentForecast() {
      return this.getOutputData.filter(({ selected }) => selected)[0]?.y;
    },
    get7DayLaggedForecast() {
      const current = this.getOutputData.findIndex(({ selected }) => selected);
      const lagged = current >= 7 ? current - 7 : 0;
      return this.getOutputData[lagged]?.y;
    },
    getCurrentTrend() {
      return this.getCurrentForecast - this.get7DayLaggedForecast;
    },
    getMaxTimestamp(state) {
      if (!state.outputData.length) {
        return;
      }
      return parseDate(
        state.outputData[state.outputData.length - 1].nextrelease,
      );
    },
    getForecastImpactData(state) {
      return state.impactData
        .filter((obj) => obj.model === state.model)
        .map((obj) => ({
          name: obj.category,
          x: parseDate(obj.kofcast_datestamp),
          y: Number(obj.value),
          color: "",
        }))
        .reduce((acc, obj) => {
          const found = acc.find((a) => a.name === obj.name);
          const value = { x: obj.x, y: obj.y, className: "" };
          if (!found) {
            acc.push({ name: obj.name, color: obj.color, data: [value] });
          } else {
            found.data.push(value);
          }
          return acc;
        }, [])
        .map((obj, indx, arr) => ({
          ...arr[indx],
          color: colorHelper.getColorSchema()[indx],
          type: "column",
          yAxis: 1,
          showInLegend: true,
          className: "",
        }));
    },
    getCategories() {
      return this.getForecastImpactData.map((obj) => ({
        name: obj.name,
        color: obj.color,
      }));
    },
    getStructuredForecastContributionsData(state) {
      const colors = colorHelper.getColorSchema();
      let colorIndex = 0;
      let colorMap = {};
      return state.contributionData.reduce((newObject, dataObject) => {
        if (!newObject[dataObject.model]) {
          newObject[dataObject.model] = { byDate: {}, byCategory: {} };
        }

        const date = parseDate(dataObject.kofcast_datestamp);
        const data = { x: date, y: Number(dataObject.value), className: "" };

        if (!newObject[dataObject.model]["byCategory"][dataObject.category]) {
          if (!colorMap[dataObject.category]) {
            colorMap[dataObject.category] = colors[colorIndex++];
          }
          newObject[dataObject.model]["byCategory"][dataObject.category] = {
            name: dataObject.category,
            color: colorMap[dataObject.category],
            data: [],
            type: "column",
            yAxis: 1,
            showInLegend: true,
            className: "",
          };
        }

        newObject[dataObject.model]["byCategory"][dataObject.category][
          "data"
        ].push(data);

        newObject[dataObject.model]["byDate"][date] =
          newObject[dataObject.model]["byDate"][date] || [];
        newObject[dataObject.model]["byDate"][date].push(data);
        return newObject;
      }, {});
    },
    getForecastContributionsData(state) {
      let newData;
      if (this.getStructuredForecastContributionsData[state.model]) {
        newData = Object.values(
          this.getStructuredForecastContributionsData[state.model][
            "byCategory"
          ],
        );
      } else {
        newData = [];
      }

      return newData;
    },
  },
  actions: {
    async loadHistogramData({
      country,
      targetVariable,
      targetPeriod,
      model,
      date,
    }) {
      const params = {
        country,
        targetvariable: targetVariable,
        targetperiod: targetPeriod,
        ...(model ? { model } : {}),
        ...(date ? { date: new Date(date).toISOString().slice(0, 10) } : {}),
      };
      const url = "/api/hist";
      const response = await httpHelper.get(url, { params });
      this.histogramData = response.data;
    },
    async loadGrowthHistoryData({ country, targetVariable, model }) {
      const params = {
        country,
        targetvariable: targetVariable,
        ...(model ? { model } : {}),
      };
      const url = "/api/historic";
      const response = await httpHelper.get(url, { params });
      this.growthHistoryData = response.data;
    },
    async loadMeasuresData({ country, targetVariable, targetPeriod, model }) {
      const params = {
        country,
        targetvariable: targetVariable,
        targetperiod: targetPeriod,
        ...(model ? { model } : {}),
      };
      const url = "/api/measures";
      const response = await httpHelper.get(url, { params });
      this.measuresData = response.data;
    },
    async loadReleaseData({
      country,
      targetVariable,
      targetPeriod,
      model,
      date,
    }) {
      const params = {
        country,
        targetvariable: targetVariable,
        targetperiod: targetPeriod,
        ...(model ? { model } : {}),
        ...(date ? { date: new Date(date).toISOString().slice(0, 10) } : {}),
      };
      const url = "/api/release";
      const response = await httpHelper.get(url, { params });
      this.releaseData = response.data;
    },
    async loadOutputData({
      country,
      targetVariable,
      targetPeriod,
      model,
      indicator,
    }) {
      const params = {
        country,
        targetvariable: targetVariable,
        targetperiod: targetPeriod,
        ...(model ? { model } : {}),
      };
      const url = "/api/output";
      // For now, all indicator=gdp are benchmarks and indicator=en are data
      // may need more sophisticated calls in the furure
      const dataPromise = httpHelper.get(url, {
        params: { ...params, indicator },
      });
      const benchmarkPromise = httpHelper.get(url, {
        params: { ...params, indicator: "gdp" },
      });
      this.outputData = [
        ...(await dataPromise).data,
        ...(await benchmarkPromise).data,
      ];
    },
    async loadImpactData({ country, targetVariable, targetPeriod, model }) {
      const params = {
        country,
        targetvariable: targetVariable,
        targetperiod: targetPeriod,
        ...(model ? { model } : {}),
      };
      const url = "/api/impact";
      const response = await httpHelper.get(url, { params });
      this.impactData = response.data;
    },
    async loadContributionData({
      country,
      targetVariable,
      targetPeriod,
      model,
    }) {
      const params = {
        country,
        targetvariable: targetVariable,
        targetperiod: targetPeriod,
        ...(model ? { model } : {}),
      };
      const url = "/api/contribution";
      const response = await httpHelper.get(url, { params });
      this.contributionData = response.data;
    },
    setActiveDay(day) {
      this.activeDay = Number(day);
    },
  },
});
