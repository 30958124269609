<template>
  <div>
    <h1 class="title is-2">News</h1>
    <div class="content-container">
      <news-item
        v-for="item in newsItems"
        :key="item.date.toString()"
        :newsItem="item"
      />
    </div>
  </div>
</template>

<script>
import NewsItem from "./NewsItem.vue";
import { formatDate } from "@/utils";
import { mapState } from "pinia";
import { useNewsStore } from "@/stores/news";
export default {
  name: "NclNews",
  components: { NewsItem },
  computed: {
    ...mapState(useNewsStore, ["newsItems"]),
  },
  methods: {
    formatDate,
  },
};
</script>

<style scoped>
.content-container {
  margin-bottom: 20px;
}
</style>
