<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="'0 0 ' + viewboxWidth + ' ' + viewboxHeight"
    :aria-labelledby="name"
    role="presentation"
    @click="$emit('click')"
  >
    <title :id="name" v-if="name">{{ name }}</title>
    <g :stroke="color" :fill="fillColor">
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  name: "NclIcon",
  props: {
    name: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    color: {
      type: String,
      default: "#000",
    },
    fillColor: {},
    viewboxHeight: {
      type: [Number, String],
      default: 24,
    },
    viewboxWidth: {
      type: [Number, String],
      default: 24,
    },
  },
};
</script>
