<script setup>
import { useCountryStore } from "@/stores/country";

const props = defineProps({ activeTargetPeriod: String });
const countryStore = useCountryStore();
</script>

<template>
  <router-link
    :to="{
      name: 'Detail',
      query: {
        country: countryStore.activeCountry.code,
        targetvariable: countryStore.activeIndicator.indicator,
        targetperiod: props.activeTargetPeriod,
      },
    }"
    target="_blank"
    :class="[$style['detail-link'], 'is-underlined']"
  >
    <slot>
      Detail Page
      <font-awesome-icon :icon="['fa', 'external-link']"></font-awesome-icon>
    </slot>
  </router-link>
</template>

<style module>
.detail-link {
  color: rgb(215, 43, 31);
}
</style>
