<template>
  <NclIcon
    :name="name"
    :width="width"
    :height="height"
    :color="color"
    @click="$emit('click')"
  >
    <rect
      x="6"
      y="6"
      width="12"
      height="12"
      :fill="color"
      rx="6"
      ry="6"
      data-z-index="3"
    ></rect>
  </NclIcon>
</template>

<script>
import NclIcon from "@/components/icons/NclIcon.vue";

export default {
  name: "CircleIcon",
  components: {
    NclIcon,
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    color: {
      type: String,
      default: "#000",
    },
  },
};
</script>
