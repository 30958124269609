<script setup>
import NclModal from "@/components/NclModal.vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";

const props = defineProps({
  releaseDate: {
    type: String,
    required: true,
  },
  release: {
    type: Object,
    required: true,
  },
});
const release = props.release;
</script>

<template>
  <ncl-modal title="Data Release Details">
    <h6 class="text-h6 text-center">
      {{ release.title }}
    </h6>
    <v-list lines="two">
      <v-list-item
        :class="$style.padded"
        title="Release Date"
        :subtitle="releaseDate"
      />
      <v-list-item
        :class="$style.padded"
        title="Reference Period"
        :subtitle="release.referencePeriod"
      />
      <v-list-item
        :class="$style.padded"
        title="Unit"
        :subtitle="release.unit"
      />
      <v-list-item
        :class="$style.padded"
        title="Previous Value"
        :subtitle="release.previousValue"
      />
      <v-list-item
        :class="$style.padded"
        title="Current Value"
        :subtitle="release.actualValue"
      />
      <v-list-item
        :class="$style.padded"
        title="Impact"
        :subtitle="release.impact"
      />
      <v-list-item :class="$style.padded">
        <v-list-item-title>Category</v-list-item-title>
        <v-list-item-subtitle style="display: flex"
          ><CircleIcon :color="release.color" />
          {{ release.category }}</v-list-item-subtitle
        >
      </v-list-item>
      <v-list-item
        :class="$style.padded"
        title="Description"
        :subtitle="release.description"
      />
      <v-list-item
        :class="$style.padded"
        title="Source"
        :subtitle="release.source"
      />
    </v-list>
  </ncl-modal>
</template>

<style module>
.padded :global(.v-list-item-subtitle) {
  padding-left: 2rem;
}
</style>
