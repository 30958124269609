import { createRouter, createWebHistory } from "vue-router";

// Views
import NclHome from "@/components/NclHome.vue";
import NclDetail from "@/components/NclDetail.vue";
import NclNews from "@/components/NclNews.vue";
import NclPartners from "@/components/NclPartners.vue";

let router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/map",
      name: "Home",
      component: NclHome,
    },
    {
      path: "/detail",
      name: "Detail",
      component: NclDetail,
      props(route) {
        return {
          country: route.query.country,
          targetVariable: route.query.targetVariable,
          targetperiod: route.query.targetperiod,
        };
      },
    },
    {
      path: "/about",
      name: "About",
      component: () => import("@/components/NclAbout.vue"),
    },
    {
      path: "/news",
      name: "News",
      component: NclNews,
    },
    {
      path: "/partners",
      name: "Partners",
      component: NclPartners,
    },
    {
      path: "/researchers-developers",
      name: "Researchers-Developers",
      component: () => import("@/components/NclResearchersDevelopers.vue"),
    },

    // Catch empty
    { path: "/", redirect: "/map" },
  ],
});

export default router;
