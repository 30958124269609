import { createVuetify } from "vuetify";
import "@/styles/main.scss";
import { aliases, fa } from "vuetify/iconsets/fa-svg";

const nclTheme = {
  dark: false,
  colors: {
    background: "#FFFFFF",
    surface: "#FFFFFF",
    primary: "#7CB5EC",
    "primary-darken-1": "#4578c6",
    secondary: "#FFA500",
    "secondary-darken-1": "#f77900",
    error: "#bc1d10",
    info: "#c2def7",
    success: "#00736b",
    warning: "#fc8a00",
  },
};

export default createVuetify({
  theme: {
    defaultTheme: "nclTheme",
    themes: {
      nclTheme: nclTheme,
    },
  },
  icons: {
    defaultSet: "fa",
    aliases,
    sets: {
      fa,
    },
  },
});
