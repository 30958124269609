<script setup>
const props = defineProps({
  title: {
    type: String,
    required: false,
    default: "",
  },
});

const emit = defineEmits(["close"]);
</script>

<template>
  <v-card>
    <v-toolbar v-if="props.title" color="primary" :title="props.title" />
    <v-card-text>
      <slot></slot>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn variant="text" @click="emit('close')">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>
