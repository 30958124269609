<template>
  <div id="legend"></div>
</template>

<script>
import {
  select as d3select,
  scaleLinear as d3scaleLinear,
  format as d3format,
} from "d3";
import { mapStores } from "pinia";
import { useCountryStore } from "@/stores/country";

export default {
  data() {
    return {
      legendSVG: null,
    };
  },
  computed: {
    ...mapStores(useCountryStore),
  },
  props: ["indicatorValues"],
  methods: {
    buildLegend() {
      if (this.legendSVG) {
        this.legendSVG.remove();
      }
      const firstColor = this.countryStore.colors[0];
      const lastColor =
        this.countryStore.colors[this.countryStore.colors.length - 1];

      this.legendSVG = d3select("#legend")
        .append("svg")
        .attr("height", "250px")
        .attr("width", "150px");

      const linear = d3scaleLinear(this.indicatorValues, [
        firstColor,
        lastColor,
      ]);

      this.legendSVG.append("g").attr("class", "legend");

      const stepSize = (this.indicatorValues[1] - this.indicatorValues[0]) / 7;
      const values = Array.from("x".repeat(8)).map(
        (_value, index) => this.indicatorValues[1] - index * stepSize,
      );
      const group = this.legendSVG
        .select(".legend")
        .selectAll("g")
        .data(values)
        .enter()
        .append("g")
        .attr("transform", function (_value, index) {
          return `translate(0, ${index * 17})`;
        });

      group
        .append("rect")
        .attr("width", 15)
        .attr("height", 15)
        .style("fill", linear)
        .style("stroke", linear);
      group
        .append("text")
        .attr("class", "label")
        .attr("transform", "translate(25, 12.5)")
        .text(function (value) {
          return d3format(".2f")(value) + "%";
        });
    },
  },
  watch: {
    indicatorValues() {
      this.buildLegend();
    },
  },
  mounted() {
    this.buildLegend();
  },
};
</script>

<style>
#legend svg text.label {
  font-weight: 400;
  font-size: 13px;
}

#legend svg g {
  height: 500px;
}
</style>
