import axios from "redaxios";

export default {
  get,
  post,
  put,
};

function getHttpInst() {
  let configHttp = {
    baseURL: "",
  };
  return axios.create(configHttp);
}
function get(url, config = {}) {
  return getHttpInst().get(url, config);
}
function post(url, body, config = {}) {
  return getHttpInst().post(url, body, config);
}
function put(url, body, config = {}) {
  return getHttpInst().put(url, body, config);
}
