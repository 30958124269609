import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faLongArrowUp,
  faLongArrowDown,
  faMinus,
  faExternalLink,
  faInfo,
  faFileDownload,
  faInfoCircle,
  faTimes,
  faExclamation,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { createHead, VueHeadMixin } from "@unhead/vue";
import { createPlausible } from "v-plausible/vue";
import vuetify from "@/plugins/vuetify";

const app = createApp(App);

app.use(vuetify);

// Plausible tracking
const plausible = createPlausible({
  init: {
    domain:
      process.env.NODE_ENV === "production"
        ? "nowcastinglab.org"
        : "dev.nowcastinglab.org",
    trackLocalhost: true,
  },
  settings: {
    enableAutoOutboundTracking: false,
    enableAutoPageviews: false,
  },
  partytown: false,
});

app.use(plausible);

const head = createHead();
app.use(head);
app.mixin(VueHeadMixin);

// Font Awesome Icons
app.component("font-awesome-icon", FontAwesomeIcon);
library.add(
  faLongArrowUp,
  faLongArrowDown,
  faMinus,
  faExternalLink,
  faInfo,
  faFileDownload,
  faInfoCircle,
  faTimes,
  faExclamation,
  faExclamationTriangle,
);

const pinia = createPinia();
app.use(pinia);
app.use(router);

app.mount("#app");
