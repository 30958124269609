<template>
  <nav class="navbar">
    <div class="navbar-item">
      <a href="https://www.ethz.ch" target="_blank">
        <img class="image" src="./../assets/logos/eth.svg" alt="ETH Logo" />
      </a>
    </div>
    <div class="navbar-item">
      <a href="https://kof.ethz.ch" target="_blank">
        <img
          class="image kof-image"
          src="./../assets/logos/kof.svg"
          alt="KOF Logo"
        />
      </a>
    </div>
    <div class="navbar-item">
      <router-link :to="{ name: 'About' }" class="external-link"
        >About Nowcasting Lab</router-link
      >
    </div>
    <div class="navbar-item">
      <router-link :to="{ name: 'News' }" target="_blank" class="external-link">
        News
      </router-link>
    </div>
    <div class="navbar-item">
      <a
        class="external-link"
        href="https://blognowcastinglab.wordpress.com"
        @click="trackOutgoing"
        target="_blank"
        >Blog</a
      >
    </div>
    <div class="navbar-item">
      <router-link
        :to="{ name: 'Partners' }"
        target="_blank"
        class="external-link"
      >
        Partner Institutions
      </router-link>
    </div>
    <div class="navbar-item">
      <router-link
        :to="{ name: 'Researchers-Developers' }"
        class="external-link"
        >Researchers &amp; Developers</router-link
      >
    </div>
    <!--
    <div class="navbar-item">
      <a
        class="external-link"
        href="https://kof.ethz.ch/en/footer/imprint.html"
        @click="trackOutgoing"
        target="_blank"
        >Imprint</a
      >
    </div>
    -->
    <div class="navbar-item">
      <a
        class="external-link"
        href="https://kof.ethz.ch/en/footer/disclaimer-copyright.html"
        @click="trackOutgoing"
        target="_blank"
        >Disclaimer & Copyright</a
      >
    </div>
    <div class="navbar-item">
      <a
        class="external-link"
        href="https://kof.ethz.ch/en/footer/data-protection.html"
        @click="trackOutgoing"
        target="_blank"
        >Data Protection</a
      >
    </div>
    <div class="navbar-item">
      <a
        class="external-link"
        href="mailto:nowcastinglab@kof.ethz.ch"
        @click="trackMailTo"
        target="_blank"
        >Give us Feedback</a
      >
    </div>
  </nav>
</template>

<script>
import { usePlausible } from "v-plausible/vue";

export default {
  name: "NclFooter",
  setup() {
    const { trackEvent } = usePlausible();
    return { trackEvent };
  },
  methods: {
    trackOutgoing: function (e) {
      this.trackEvent("Outbound Link: Click", {
        props: { url: e.target.href },
      });
    },
    trackMailTo: function (e) {
      const url = new URL(e.target.href);
      this.trackEvent("mailto", {
        props: { recipient: url.pathname },
      });
    },
  },
};
</script>

<style scoped>
.kof-image {
  height: 30.74px;
}

a.external-link {
  color: #666666;
  cursor: pointer;
}

a.external-link:hover {
  color: #000;
}
</style>
