<template>
  <div id="world-map" class="svg-container" ref="worldmap"></div>
</template>

<script>
import {
  select as d3select,
  geoMercator as d3GeoMercator,
  geoPath as d3GeoPath,
  geoGraticule as d3GeoGraticule,
  xml as d3xml,
} from "d3";
import { feature } from "topojson-client";
import { mapStores, mapState } from "pinia";
import { useCountryStore } from "@/stores/country";
import world_map from "@/assets/map/europe.json";
import ecb from "@/assets/flags/4x3/ea.svg";
import us_flag from "@/assets/flags/4x3/us.svg";

export default {
  data() {
    return {
      worldMap: world_map,
      mapSVG: null,
      path: null,
      projectionScale: null,
    };
  },
  computed: {
    ...mapStores(useCountryStore),
    ...mapState(useCountryStore, ["activeCountry"]),
  },
  props: ["countryColors", "indicatorValues", "activeTargetPeriod"],
  watch: {
    countryColors() {
      this.updateData();
    },
    activeCountry() {
      this.updateData();
    },
  },
  methods: {
    changeCountry(country) {
      let clickedCountry = this.countryStore.areas.find((ele) =>
        ele.countries.includes(country.id),
      );
      if (clickedCountry) {
        this.countryStore.activeCountry = clickedCountry;
      }
    },
    createSvg() {
      const height = this.$refs.worldmap.offsetHeight;
      const width = this.$refs.worldmap.offsetWidth;

      this.mapSVG = d3select("#world-map")
        .append("svg")
        .attr("viewBox", "0 0 " + width + " " + height)
        .attr("preserveAspectRatio", "xMaxYMid meet")
        .call(this.responsivefy) // uncomment this section to add zoom capability to map
        /*.call(d3zoom().scaleExtent([1, 8])
                    .on("zoom", (event) => {
                        this.mapSVG.attr("transform", event.transform);
                    })
                )*/ .on("dblclick.zoom", null)
        .append("g");

      let projectionCoefficient = 1.5;
      if (height < 450) {
        projectionCoefficient = 2.1;
      }

      let projection = d3GeoMercator()
        .center([0, 55])
        .translate([width / 2, height / 2])
        .scale([width / projectionCoefficient]);

      let tooltip = d3select("#world-map")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0);

      this.path = d3GeoPath().projection(projection);

      const graticule = d3GeoGraticule();

      this.mapSVG
        .append("path")
        .datum(graticule)
        .attr("class", "graticule")
        .attr("d", this.path);

      let heightDenominator = 12;
      let xPercentage = "-19%";

      if (height <= 546) {
        heightDenominator = 15;
      }

      if (height <= 530) {
        heightDenominator = 12;
        xPercentage = "-7.5%";
      }

      if (height <= 410) {
        heightDenominator = 10;
        xPercentage = "-0.5%";
      }

      if (height <= 360) {
        heightDenominator = 10;
        xPercentage = "-1%";
      }

      if (height <= 320) {
        heightDenominator = 10;
        xPercentage = "-7%";
      }

      d3xml(ecb).then((data) => {
        data.documentElement.classList.add("ea-flag");
        this.mapSVG.node().append(data.documentElement);
        let innerSVG = this.mapSVG.select(".ea-flag");
        innerSVG
          .attr("viewBox", "0 0 400 320")
          .attr("height", height / heightDenominator)
          .attr("x", xPercentage)
          .attr("y", "60%")
          .attr("class", "ea-flag")
          .on("click", () => {
            this.countryStore.activeCountry = this.countryStore.areas.find(
              (area) => area.code === "ea_agg",
            );
          })
          .on("mouseover", (event) => {
            let country = this.countryStore.areas.find(
              (area) => area.code === "ea_agg",
            );

            if (country !== undefined) {
              let indicator = this.countryStore.activeIndicator.name;
              let value = this.countryStore.getIndicatorValueFromCountry(
                country.code,
                this.countryStore.activeIndicator.indicator,
                this.activeTargetPeriod,
              );
              if (value) {
                let tooltipContent =
                  country.name +
                  "</br>" +
                  indicator +
                  " growth forecast " +
                  "</br>" +
                  value.toFixed(2) +
                  "%";
                tooltip.transition().duration(200).style("opacity", 0.9);
                tooltip
                  .html(tooltipContent)
                  .style("left", event.layerX + 50 + "px")
                  .style("top", event.layerY + "px");
              }
            }
          })
          .on("mouseout", () => {
            tooltip.transition().duration(200).style("opacity", 0);
          });
      });

      (async function (component) {
        const data = await d3xml(us_flag);
        data.documentElement.classList.add("us-flag");
        component.mapSVG.node().append(data.documentElement);
        let innerSVG = component.mapSVG.select(".us-flag");
        const usDenominator = 1.5;
        innerSVG
          .attr("height", height / heightDenominator / usDenominator)
          .attr("x", xPercentage)
          .attr("y", "40%")
          .attr("class", "us-flag")
          .on("click", () => {
            component.countryStore.activeCountry =
              component.countryStore.areas.find((area) => area.code === "us");
          })
          .on("mouseover", (event) => {
            let country = component.countryStore.areas.find(
              (area) => area.code === "us",
            );

            if (country !== undefined) {
              let indicator = component.countryStore.activeIndicator.name;
              let value = component.countryStore.getIndicatorValueFromCountry(
                country.code,
                component.countryStore.activeIndicator.indicator,
                component.activeTargetPeriod,
              );
              if (value) {
                let tooltipContent =
                  country.name +
                  "</br>" +
                  indicator +
                  " growth forecast " +
                  "</br>" +
                  value.toFixed(2) +
                  "%";
                tooltip.transition().duration(200).style("opacity", 0.9);
                tooltip
                  .html(tooltipContent)
                  .style("left", event.layerX + 50 + "px")
                  .style("top", event.layerY + "px");
              }
            }
          })
          .on("mouseout", () => {
            tooltip.transition().duration(200).style("opacity", 0);
          });
      })(this);

      const countries = feature(
        this.worldMap,
        this.worldMap.objects.countries,
      ).features;

      this.mapSVG
        .selectAll("g")
        .data(countries)
        .enter()
        .append("path")
        .attr("class", "country")
        .attr("d", this.path)
        .on("mouseover", (event, d) => {
          let country = this.countryStore.areas.find((ele) =>
            ele.countries.includes(d.id),
          );

          if (country !== undefined) {
            let indicator = this.countryStore.activeIndicator.name;
            let value = this.countryStore.getIndicatorValueFromCountry(
              country.code,
              this.countryStore.activeIndicator.indicator,
              this.activeTargetPeriod,
            );
            if (value && country.code !== "ea_agg") {
              let tooltipContent =
                country.name +
                "</br>" +
                indicator +
                " growth forecast " +
                "</br>" +
                value.toFixed(2) +
                "%";
              tooltip.transition().duration(200).style("opacity", 0.9);
              tooltip
                .html(tooltipContent)
                .style("left", event.layerX - 150 + "px")
                .style("top", event.layerY + "px");
            }
          }
        })
        .on("mouseout", () => {
          tooltip.transition().duration(200).style("opacity", 0);
        })
        .on("click", (_event, d) => {
          this.changeCountry(d);
        });
    },
    updateData() {
      this.mapSVG
        .selectAll("path.country")
        .transition()
        .style("fill", (d) => {
          if (
            this.countryColors[d.id] &&
            this.countryColors[d.id].color !== null
          ) {
            return this.countryColors[d.id].color;
          }
          return "#D3D3D3";
        })
        .style("stroke-width", (d) => {
          if (d.id.toLowerCase() === this.activeCountry.code) {
            return "1.5px";
          } else {
            return ".2px";
          }
        })
        .style("stroke", (d) => {
          if (d.id.toLowerCase() === this.activeCountry.code) {
            return "#FFA500";
          } else {
            return "#000";
          }
        });

      this.mapSVG
        .select("svg") // ea-flag
        .style("stroke-width", () => {
          if (this.activeCountry.code === "ea_agg") {
            return "7px";
          } else {
            return "2px";
          }
        })
        .style("stroke", () => {
          if (this.activeCountry.code === "ea_agg") {
            return "#FFA500";
          } else {
            return "none";
          }
        });
    },
    responsivefy(svg) {
      const container = d3select(svg.node().parentNode),
        width = parseInt(svg.style("width")),
        height = parseInt(svg.style("height")),
        aspect = width / height;

      svg
        .attr("viewBox", "0 0 " + width + " " + height)
        .attr("perserveAspectRatio", "xMaxYMid meet")
        .call(resize);

      d3select(window).on("resize." + container.attr("id"), resize);

      function resize() {
        const targetWidth = parseInt(container.style("width"));
        svg.attr("width", targetWidth);
        svg.attr("height", Math.round(targetWidth / aspect));
      }
    },
  },
  mounted() {
    this.createSvg();
    this.updateData();
  },
};
</script>

<style>
.svg-container {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.country {
  fill: #888;
  stroke: black;
  stroke-width: 0.2px;
  cursor: pointer;
}

.graticule {
  fill: none;
  stroke: #ccc;
  stroke-width: 0.2px;
}

.ea-flag {
  position: absolute;
  cursor: pointer;
}

.us-flag {
  position: absolute;
  cursor: pointer;
}

.axis text {
  font: 12px "IBM Plex Sans";
  color: #666666;
  fill: #666666;
}

.axis line,
.axis path {
  stroke: #e6e6e6;
  shape-rendering: crispEdges;
}

.tick line {
  stroke: #e6e6e6;
}

.tooltip {
  position: absolute;
  text-align: center;
  width: 90px;
  padding: 2px;
  font: 12px "IBM Plex Sans";
  color: #666666;
  fill: #666666;
  background: rgba(247, 247, 247, 0.85);
  border: 1px #7cb5ec solid;
  opacity: 0.99999999996;
  box-shadow: 1px 1px 2px #888;
  pointer-events: none;
  overflow: visible;
}
</style>
