<script setup>
const props = defineProps({
  iso: { type: String, required: true },
});
</script>
<template>
  <span :class="`fi fis fi-${props.iso}`"></span>
</template>

<style>
@import url("flag-icons/css/flag-icons.min.css");

.fi {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}

.fi.fis {
  width: 1em;
  height: 1em;
}

.fi-ea_agg {
  background-image: url(../assets/flags/1x1/ea.svg);
}
</style>
