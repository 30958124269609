import httpHelper from "./modules/http.helper";
import colorHelper from "./modules/color.helper";
import Cookies from "js-cookie";
import { parse } from "date-fns";
import { UTCDate } from "@date-fns/utc";

function parseDate(date) {
  return parse(date, "dd.MM.yyyy", new UTCDate()).valueOf();
}

function formatDate(date) {
  date = new Date(date);
  const options = { year: "numeric", month: "short", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
}

function dateToQuartal(date) {
  return Math.floor((date.getMonth() + 3) / 3);
}

/**
 * Choose an experiment to run from the provided options. Sticks across browser sessions.
 *
 * @param {string} name The name of the experiment.
 * @param {string[]} options The available options to choose from.
 * @returns {string} The name of the option that has been selected.
 */
function chooseExperiment(name, options) {
  const params = new URLSearchParams(location.search);
  const paramsExperiment = params.get("experiment");
  if (paramsExperiment && options.includes(paramsExperiment)) {
    return paramsExperiment;
  }
  const cookieName = `experiment_${name}`;
  const existingExperiment = Cookies.get(cookieName);
  if (existingExperiment && options.includes(existingExperiment)) {
    return existingExperiment;
  }
  const optionIndex = Math.floor(Math.random() * options.length);
  const experiment = options[optionIndex];
  Cookies.set(cookieName, experiment, { expires: 15 });
  return experiment;
}

export {
  httpHelper,
  colorHelper,
  parseDate,
  dateToQuartal,
  formatDate,
  chooseExperiment,
};
