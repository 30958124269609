<script setup>
import NclModal from "@/components/NclModal.vue";

const modelDescriptions = {
  pooled: {
    title: "Pooled",
    description:
      "Pooled projection over the (equally weighted) projections from the four model classes Bridge, MIDAS, UMIDAS, and DFM. Random Walk, Rolling Mean, Direct AR, ARIMA, and Iterative AR do not enter the pooling.",
  },
  bridge: {
    title: "Bridge",
    description:
      "Single-predictor Bridge equations model as described in Schumacher (International Journal of Forecasting, 2006)",
  },
  midas: {
    title: "MIDAS",
    description:
      "Autoregressive single-predictor mixed data sampling model along the lines of, e.g., Ghysels, Sinko, and Valkanov (Econometric Reviews, 2007) or Clements and Galvão (Journal of Business & Economic Statistics, 2008)",
  },
  umidas: {
    title: "UMIDAS",
    description:
      "Autoregressive single-predictor unrestricted MIDAS model as described in Foroni, Marcellino, and Schumacher (Journal of the Royal Statistical Society: Series A, 2015)",
  },
  dfm: {
    title: "DFM",
    description:
      "Mixed-frequency dynamic factor model as described in Banbura, Giannone, and Reichlin (Oxford Handbook on Economic Forecasting, 2011)",
  },
  "mf-esn": {
    title: "MF-ESN",
    description:
      "Multi-frequency echo state network model following Ballarin, Dellaportas, Grigoryeva, Hirt, van Huellen, and Ortega (International Journal of Forecasting, 2024).",
  },
  randomwalk: {
    title: "Random Walk",
    description:
      "Random walk forecast, i.e. nowcast and one-step ahead forecast for quarter-on-quarter GDP growth are equal to the last quarter-on-quarter GDP growth realization",
  },
  rollingmean: {
    title: "Rolling Mean",
    description:
      "Rolling in-sample mean forecast with the optimal in-sample window length (between 2 and 40 quarters) selected according to the root mean squared forecast error",
  },
  iterativear: {
    title: "Iterative AR",
    description:
      "Iterative autoregressive (AR) model with the optimal lag length determined according to the Bayesian Information Criterion (BIC)",
  },
  arima: {
    title: "ARIMA",
    description:
      "Autoregressive integrated moving-average (ARIMA) model, where the number of autoregressive lags, moving average lags, and the order of differencing are determined by BIC",
  },
  directar: {
    title: "Direct AR",
    description:
      "Direct AR model with the optimal lag length determined according to BIC",
  },
};
</script>

<template>
  <ncl-modal title="Model Information">
    <h6 class="text-h6 text-center">Nowcasting Lab Models</h6>
    <v-list>
      <v-list-item
        v-for="(model, key) in modelDescriptions"
        :key="key"
        :title="model.title"
        :subtitle="model.description"
      ></v-list-item>
    </v-list>
    <p :class="$style['modal-paragraph']">
      Bridge, MIDAS and UMIDAS come with a weighted averaging forecast
      combinations procedure as described in Stock and Watson (Journal of
      Forecasting, 2004) or Kuzin, Marcellino, and Schumacher (Journal of
      Applied Econometrics, 2013). Upstream of all models is an elastic net
      variable selection along the lines of Zou and Hastie (Journal of the Royal
      Statistical Society: Series B, 2005).
    </p>
    <p :class="$style['modal-paragraph']">
      The method paper under "About Nowcasting Lab" provides a detailed
      documentation of the applied models and techniques.
    </p>
  </ncl-modal>
</template>

<style module>
.modal-paragraph {
  margin-bottom: 10px;
}
</style>
