<script setup>
const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  location: {
    validator(value) {
      return [
        "bottom",
        "top",
        "right",
        "left",
        "start",
        "end",
        "center",
        "center center",
      ].includes(value);
    },
    default: "end",
  },
  secondaryColor: {
    type: Boolean,
    required: false,
    default: false,
  },
});
</script>

<template>
  <span
    :class="{
      'info-icon': true,
      [$style['info-icon']]: true,
      [$style['info-secondary']]: props.secondaryColor,
    }"
  >
    <font-awesome-icon size="2xs" :icon="['fa', 'info']"></font-awesome-icon>
    <v-tooltip
      :text="props.text"
      :location="props.location"
      activator="parent"
    />
  </span>
</template>

<style module>
.info-icon {
  display: inline-flex;
  color: #333333;
}

.info-icon:hover {
  color: rgb(124, 181, 236);
}

.info-secondary :hover {
  color: #666666;
}

.info-icon svg {
  border: 2px solid;
  border-radius: 50%;
  padding: 0.2em;
  width: 1em;
}
</style>
